import Icon from "@ant-design/icons";

const SearchSvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_267_557)">
      <path
        d="M11.8368 10.3748L9.49991 8.03828C9.39444 7.93282 9.25146 7.87423 9.10145 7.87423H8.71939C9.36631 7.04697 9.75071 6.00644 9.75071 4.87452C9.75071 2.18182 7.56852 0 4.87536 0C2.18219 0 0 2.18182 0 4.87452C0 7.56723 2.18219 9.74905 4.87536 9.74905C6.00747 9.74905 7.04817 9.36471 7.87558 8.7179V9.09989C7.87558 9.24988 7.93417 9.39283 8.03965 9.49829L10.3765 11.8348C10.5969 12.0551 10.9531 12.0551 11.1711 11.8348L11.8345 11.1716C12.0548 10.9513 12.0548 10.5951 11.8368 10.3748ZM4.87536 7.87423C3.2182 7.87423 1.87514 6.53374 1.87514 4.87452C1.87514 3.21765 3.21586 1.87482 4.87536 1.87482C6.53251 1.87482 7.87558 3.21531 7.87558 4.87452C7.87558 6.53139 6.53485 7.87423 4.87536 7.87423Z"
        fill="#838484"
      />
    </g>
    <defs>
      <clipPath id="clip0_267_557">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const HomeSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_412_852)">
      <path
        d="M13 0L1 10.2857H4V24H10V17.1429H16V24H22V10.1829L25 10.2857L13 0Z"
        fill="#2ADAF2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_412_852"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_412_852"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_412_852"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const AbsenceSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_412_342)">
      <path
        d="M3.25 0C2.02 0 1 1.16571 1 2.57143V21.4286C1 22.8343 2.02 24 3.25 24H22.75C23.98 24 25 22.8343 25 21.4286V2.57143C25 1.16571 23.98 0 22.75 0H3.25ZM4 3.42857H7V6.85714H4V3.42857ZM10 3.42857H22V6.85714H10V3.42857ZM4 10.2857H7V13.7143H4V10.2857ZM10 10.2857H22V13.7143H10V10.2857ZM4 17.1429H7V20.5714H4V17.1429ZM10 17.1429H22V20.5714H10V17.1429Z"
        fill="#2ADAF2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_412_342"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_412_342"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_412_342"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const CashSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_412_282)">
      <path
        d="M10.6287 0V2.99625H7.03593C3.77844 2.99625 1.0479 4.70412 1.0479 6.74157V8.2397C1.0479 10.2772 3.15569 11.9551 6.26946 12.4644L18.5329 14.4419C19.2036 14.5618 20.1617 15.3109 20.1617 15.7603V17.2584C20.1617 17.6779 19.6347 18.0075 18.9641 18.0075H6.98802C6.41317 18.0075 5.98204 17.8876 5.79042 17.8277V15.0112H1V18.0075C1 19.0262 1.95808 19.8951 3.10778 20.3446C4.20958 20.824 5.5988 21.0037 6.98802 21.0037H10.5808V24H15.3713V21.0037H18.9641C22.2695 21.0037 24.9521 19.3258 24.9521 17.2584V15.7603C24.9521 13.7228 22.8443 12.0449 19.7305 11.5356L7.46707 9.55805C6.79641 9.4382 5.83832 8.68914 5.83832 8.2397V6.74157C5.83832 6.3221 6.36527 5.99251 7.03593 5.99251H19.012C19.5389 5.99251 20.018 6.11236 20.2096 6.17228V8.98876H25V5.99251C25 4.97378 24.0419 4.10487 22.8922 3.65543C21.7904 3.17603 20.4012 2.99625 19.012 2.99625H15.4192V0L10.6287 0Z"
        fill="#2ADAF2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_412_282"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_412_282"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_412_282"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const AccountSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_412_233)">
      <path
        d="M13 0C9.7 0 7 3.36 7 7.5C7 11.64 9.7 15 13 15C16.3 15 19 11.64 19 7.5C19 3.36 16.3 0 13 0ZM6.73 15C3.55 15.15 1 17.76 1 21V24H25V21C25 17.76 22.48 15.15 19.27 15C17.65 16.83 15.43 18 13 18C10.57 18 8.35 16.83 6.73 15Z"
        fill="#2ADAF2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_412_233"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_412_233"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_412_233"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const MoreMenuSvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75 4.3125C6.68281 4.3125 7.4375 5.06719 7.4375 6C7.4375 6.93281 6.68281 7.6875 5.75 7.6875C4.81719 7.6875 4.0625 6.93281 4.0625 6C4.0625 5.06719 4.81719 4.3125 5.75 4.3125ZM4.0625 1.875C4.0625 2.80781 4.81719 3.5625 5.75 3.5625C6.68281 3.5625 7.4375 2.80781 7.4375 1.875C7.4375 0.942188 6.68281 0.1875 5.75 0.1875C4.81719 0.1875 4.0625 0.942188 4.0625 1.875ZM4.0625 10.125C4.0625 11.0578 4.81719 11.8125 5.75 11.8125C6.68281 11.8125 7.4375 11.0578 7.4375 10.125C7.4375 9.19219 6.68281 8.4375 5.75 8.4375C4.81719 8.4375 4.0625 9.19219 4.0625 10.125Z"
      fill="black"
    />
  </svg>
);

const CloseSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2803 4.71967C19.5732 5.01256 19.5732 5.48744 19.2803 5.78033L5.78033 19.2803C5.48744 19.5732 5.01256 19.5732 4.71967 19.2803C4.42678 18.9874 4.42678 18.5126 4.71967 18.2197L18.2197 4.71967C18.5126 4.42678 18.9874 4.42678 19.2803 4.71967Z"
      fill="#B8B8B8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L19.2803 18.2197C19.5732 18.5126 19.5732 18.9874 19.2803 19.2803C18.9874 19.5732 18.5126 19.5732 18.2197 19.2803L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967Z"
      fill="#B8B8B8"
    />
  </svg>
);

const AdminSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_419_822)">
      <path
        d="M11.5 0C8.59 0 5.98 1.2 4.09 3.09L13 12V0.09C12.52 0.03 12.01 0 11.5 0ZM16 3.18V13.41L7.84 21.57C9.67 23.07 11.95 24 14.5 24C20.29 24 25 19.29 25 13.5C25 8.22 21.07 3.93 16 3.18ZM3.73 7.11C2.05 8.73 1 10.98 1 13.5C1 16.38 2.38 18.87 4.48 20.52L10.87 14.13L3.73 7.11Z"
        fill="#2ADAF2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_419_822"
        x="0"
        y="0"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_419_822"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_419_822"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const TaskSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 11.5C9.26522 11.5 9.51957 11.3946 9.70711 11.2071C9.89464 11.0196 10 10.7652 10 10.5C10 10.2348 9.89464 9.98043 9.70711 9.79289C9.51957 9.60536 9.26522 9.5 9 9.5C8.73478 9.5 8.48043 9.60536 8.29289 9.79289C8.10536 9.98043 8 10.2348 8 10.5C8 10.7652 8.10536 11.0196 8.29289 11.2071C8.48043 11.3946 8.73478 11.5 9 11.5ZM11 10.5C11 10.2348 11.1054 9.98043 11.2929 9.79289C11.4804 9.60536 11.7348 9.5 12 9.5H15C15.2652 9.5 15.5196 9.60536 15.7071 9.79289C15.8946 9.98043 16 10.2348 16 10.5C16 10.7652 15.8946 11.0196 15.7071 11.2071C15.5196 11.3946 15.2652 11.5 15 11.5H12C11.7348 11.5 11.4804 11.3946 11.2929 11.2071C11.1054 11.0196 11 10.7652 11 10.5ZM12 12.5C11.7348 12.5 11.4804 12.6054 11.2929 12.7929C11.1054 12.9804 11 13.2348 11 13.5C11 13.7652 11.1054 14.0196 11.2929 14.2071C11.4804 14.3946 11.7348 14.5 12 14.5H15C15.2652 14.5 15.5196 14.3946 15.7071 14.2071C15.8946 14.0196 16 13.7652 16 13.5C16 13.2348 15.8946 12.9804 15.7071 12.7929C15.5196 12.6054 15.2652 12.5 15 12.5H12ZM12 15.5C11.7348 15.5 11.4804 15.6054 11.2929 15.7929C11.1054 15.9804 11 16.2348 11 16.5C11 16.7652 11.1054 17.0196 11.2929 17.2071C11.4804 17.3946 11.7348 17.5 12 17.5H15C15.2652 17.5 15.5196 17.3946 15.7071 17.2071C15.8946 17.0196 16 16.7652 16 16.5C16 16.2348 15.8946 15.9804 15.7071 15.7929C15.5196 15.6054 15.2652 15.5 15 15.5H12ZM10 13.5C10 13.7652 9.89464 14.0196 9.70711 14.2071C9.51957 14.3946 9.26522 14.5 9 14.5C8.73478 14.5 8.48043 14.3946 8.29289 14.2071C8.10536 14.0196 8 13.7652 8 13.5C8 13.2348 8.10536 12.9804 8.29289 12.7929C8.48043 12.6054 8.73478 12.5 9 12.5C9.26522 12.5 9.51957 12.6054 9.70711 12.7929C9.89464 12.9804 10 13.2348 10 13.5ZM9 17.5C9.26522 17.5 9.51957 17.3946 9.70711 17.2071C9.89464 17.0196 10 16.7652 10 16.5C10 16.2348 9.89464 15.9804 9.70711 15.7929C9.51957 15.6054 9.26522 15.5 9 15.5C8.73478 15.5 8.48043 15.6054 8.29289 15.7929C8.10536 15.9804 8 16.2348 8 16.5C8 16.7652 8.10536 17.0196 8.29289 17.2071C8.48043 17.3946 8.73478 17.5 9 17.5Z"
      fill="#FFD804"
    />
    <path
      d="M9 2C8.73478 2 8.48043 2.10536 8.29289 2.29289C8.10536 2.48043 8 2.73478 8 3H6C5.46957 3 4.96086 3.21071 4.58579 3.58579C4.21071 3.96086 4 4.46957 4 5V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V5C20 4.46957 19.7893 3.96086 19.4142 3.58579C19.0391 3.21071 18.5304 3 18 3H16C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2H9ZM16 5H18V20H6V5H8V6C8 6.26522 8.10536 6.51957 8.29289 6.70711C8.48043 6.89464 8.73478 7 9 7H15C15.2652 7 15.5196 6.89464 15.7071 6.70711C15.8946 6.51957 16 6.26522 16 6V5ZM10 5V4H14V5H10Z"
      fill="#333333"
    />
  </svg>
);

const PartySvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1219 4.56814C16.1959 4.64222 16.2522 4.73151 16.2991 4.8294L16.3044 4.8241L23.7228 21.5352L23.7155 21.5425C23.853 21.809 23.6229 22.3514 23.1513 22.8236C22.6797 23.2952 22.1374 23.5254 21.8708 23.3878L21.8642 23.3944L5.15309 15.9754L5.15838 15.9695C5.06116 15.9232 4.97187 15.867 4.89713 15.7916C3.86401 14.7584 5.53935 11.4084 8.63804 8.30906C11.7381 5.2097 15.0881 3.53502 16.1219 4.56814Z"
      fill="#DD2E44"
    />
    <path
      d="M15.2131 7.55249L23.5362 21.1153L23.7228 21.5353L23.7155 21.5426C23.853 21.8091 23.6229 22.3515 23.1513 22.8237C22.9978 22.9772 22.8384 23.0936 22.6824 23.1921L12.5675 10.8595L15.2131 7.55249Z"
      fill="#EA596E"
    />
    <path
      d="M8.59113 8.25766C5.50235 11.3477 3.78733 14.6416 4.76224 15.6151C5.73649 16.5901 9.0303 14.8757 12.1211 11.7869C15.2105 8.69683 16.9249 5.4017 15.9506 4.42745C14.9757 3.4532 11.6819 5.16756 8.59113 8.25766Z"
      fill="#A0041E"
    />
    <path
      d="M11.5159 8.61681C11.6475 8.72329 11.8194 8.77885 12.0013 8.75901C12.5754 8.69684 13.0583 8.49709 13.3962 8.1816C13.7541 7.84759 13.9307 7.39916 13.8824 6.95006C13.7977 6.16166 13.0067 5.43809 11.6581 5.58359C11.1336 5.63981 10.8994 5.47116 10.8915 5.39046C10.8822 5.31043 11.0747 5.09548 11.5992 5.0386C12.1733 4.97642 12.6561 4.77668 12.9948 4.46119C13.3526 4.12718 13.5298 3.67874 13.4809 3.22965C13.3949 2.44125 12.6045 1.71767 11.2572 1.86384C10.875 1.90485 10.6732 1.82614 10.5879 1.77521C10.5198 1.73355 10.4927 1.69386 10.49 1.67071C10.4821 1.59068 10.6719 1.37572 11.1977 1.31884C11.5608 1.27916 11.8241 0.953747 11.7837 0.589973C11.7447 0.226861 11.42 -0.0357177 11.0555 0.00396668C9.70823 0.148815 9.08915 1.02386 9.17447 1.81291C9.26046 2.60263 10.0508 3.32489 11.3994 3.18004C11.7817 3.13837 11.9815 3.21774 12.0675 3.26867C12.1356 3.30968 12.1634 3.35002 12.166 3.37251C12.1746 3.4532 11.9835 3.6675 11.4577 3.72438C10.1104 3.86989 9.49129 4.74427 9.57661 5.53333C9.66193 6.32172 10.4523 7.0453 11.8003 6.89913C12.1826 6.85813 12.3836 6.93749 12.4696 6.98776C12.5384 7.03009 12.5648 7.06978 12.5675 7.09226C12.5761 7.17229 12.3849 7.38725 11.8598 7.44413C11.4973 7.48382 11.2334 7.80989 11.2738 8.173C11.2923 8.35423 11.3842 8.51098 11.5159 8.61681Z"
      fill="#AA8DD8"
    />
    <path
      d="M3.532 14.7336C2.22705 14.3652 1.32687 14.9472 1.11258 15.7112C0.898281 16.4744 1.36259 17.4407 2.66688 17.8078C3.17617 17.9507 3.32895 18.1941 3.30845 18.2715C3.28596 18.3495 3.02735 18.4778 2.51939 18.3343C1.21509 17.9672 0.314921 18.5493 0.100625 19.3125C-0.114994 20.0764 0.350636 21.0414 1.65559 21.4092C2.16421 21.552 2.31766 21.7961 2.29517 21.8735C2.27335 21.9509 2.0154 22.0792 1.50678 21.9363C1.15623 21.8378 0.78981 22.0421 0.69126 22.3933C0.593372 22.7452 0.797747 23.1103 1.14962 23.2095C2.45325 23.5766 3.35408 22.9959 3.5697 22.2313C3.784 21.468 3.31903 20.503 2.01341 20.1353C1.50413 19.9918 1.35135 19.749 1.37383 19.671C1.395 19.5936 1.65295 19.4646 2.16091 19.6075C3.46652 19.9752 4.36604 19.3945 4.58099 18.6293C4.79595 17.866 4.33098 16.901 3.02603 16.5326C2.51807 16.3904 2.36528 16.1457 2.38645 16.069C2.40893 15.9909 2.66622 15.8626 3.17484 16.0055C3.52671 16.1047 3.89115 15.8997 3.99036 15.5484C4.08891 15.1979 3.88387 14.8328 3.532 14.7336Z"
      fill="#77B255"
    />
    <path
      d="M8.59842 12.9496C8.79287 12.9496 8.98468 12.8643 9.11564 12.7016C9.34382 12.4158 9.29686 11.9998 9.01246 11.7716C8.86827 11.6559 5.42896 8.95469 0.568277 9.64983C0.206488 9.70142 -0.0448484 10.0361 0.00674057 10.3979C0.0583305 10.759 0.390357 11.013 0.755453 10.9588C5.04997 10.3489 8.15461 12.7803 8.18504 12.8047C8.30806 12.9026 8.45357 12.9496 8.59842 12.9496Z"
      fill="#AA8DD8"
    />
    <path
      d="M20.0057 10.1983C20.0685 10.1983 20.1327 10.1891 20.1961 10.1706C20.546 10.0654 20.7445 9.69698 20.6393 9.3471C19.8899 6.85161 19.2107 2.86928 20.0453 1.83087C20.1386 1.71314 20.2795 1.5974 20.6023 1.62187C21.2227 1.66949 21.1638 2.97841 21.1631 2.99164C21.1353 3.35608 21.4092 3.67355 21.7729 3.70067C22.1427 3.72316 22.4549 3.45463 22.482 3.09019C22.5501 2.17811 22.2664 0.421414 20.7015 0.303022C20.003 0.250109 19.423 0.492846 19.0136 1.00213C17.4454 2.95394 19.0374 8.61228 19.372 9.72741C19.458 10.0138 19.7213 10.1983 20.0057 10.1983Z"
      fill="#77B255"
    />
    <path
      d="M6.94551 6.8912C6.39758 6.8912 5.9534 6.44702 5.9534 5.89909C5.9534 5.35117 6.39758 4.90698 6.94551 4.90698C7.49344 4.90698 7.93762 5.35117 7.93762 5.89909C7.93762 6.44702 7.49344 6.8912 6.94551 6.8912Z"
      fill="#5C913B"
    />
    <path
      d="M22.4886 12.8439C21.758 12.8439 21.1658 12.2516 21.1658 11.5211C21.1658 10.7905 21.758 10.1982 22.4886 10.1982C23.2192 10.1982 23.8114 10.7905 23.8114 11.5211C23.8114 12.2516 23.2192 12.8439 22.4886 12.8439Z"
      fill="#9266CC"
    />
    <path
      d="M2.31569 13.5052C1.76776 13.5052 1.32358 13.061 1.32358 12.5131C1.32358 11.9652 1.76776 11.521 2.31569 11.521C2.86362 11.521 3.3078 11.9652 3.3078 12.5131C3.3078 13.061 2.86362 13.5052 2.31569 13.5052Z"
      fill="#5C913B"
    />
    <path
      d="M8.26833 21.4422C7.7204 21.4422 7.27622 20.998 7.27622 20.4501C7.27622 19.9022 7.7204 19.458 8.26833 19.458C8.81625 19.458 9.26044 19.9022 9.26044 20.4501C9.26044 20.998 8.81625 21.4422 8.26833 21.4422Z"
      fill="#5C913B"
    />
    <path
      d="M5.29199 3.5841C4.56142 3.5841 3.96918 2.99186 3.96918 2.26129C3.96918 1.53072 4.56142 0.938477 5.29199 0.938477C6.02256 0.938477 6.61481 1.53072 6.61481 2.26129C6.61481 2.99186 6.02256 3.5841 5.29199 3.5841Z"
      fill="#FFCC4D"
    />
    <path
      d="M2.31569 6.22958C1.76776 6.22958 1.32358 5.7854 1.32358 5.23747C1.32358 4.68954 1.76776 4.24536 2.31569 4.24536C2.86362 4.24536 3.3078 4.68954 3.3078 5.23747C3.3078 5.7854 2.86362 6.22958 2.31569 6.22958Z"
      fill="#FFCC4D"
    />
    <path
      d="M4.29988 8.87533C3.75195 8.87533 3.30777 8.43115 3.30777 7.88322C3.30777 7.3353 3.75195 6.89111 4.29988 6.89111C4.84781 6.89111 5.29199 7.3353 5.29199 7.88322C5.29199 8.43115 4.84781 8.87533 4.29988 8.87533Z"
      fill="#FFCC4D"
    />
    <path
      d="M18.8508 16.151C18.3029 16.151 17.8587 15.7068 17.8587 15.1589C17.8587 14.6109 18.3029 14.1667 18.8508 14.1667C19.3988 14.1667 19.843 14.6109 19.843 15.1589C19.843 15.7068 19.3988 16.151 18.8508 16.151Z"
      fill="#FFCC4D"
    />
  </svg>
);

const TrackingSvg = () => (
  <svg
    width="38"
    height="39"
    viewBox="0 0 38 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19.5" r="19" fill="#2ADAF2" />
    <path
      d="M23.8363 10.8638L21.5909 13.1092L26.0818 17.6001L28.3273 15.3547L23.8363 10.8638ZM19.3454 15.3547L10.3636 24.3365V28.8274H14.8545L23.8363 19.8456L19.3454 15.3547Z"
      fill="white"
    />
  </svg>
);

const TrackingNonActiveSvg = () => (
  <svg
    width="38"
    height="39"
    viewBox="0 0 38 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19.5" r="19" fill="#D0D0D0" />
    <path
      d="M23.8364 10.8638L21.5909 13.1092L26.0818 17.6001L28.3273 15.3547L23.8364 10.8638ZM19.3455 15.3547L10.3636 24.3365V28.8274H14.8546L23.8364 19.8456L19.3455 15.3547Z"
      fill="white"
    />
  </svg>
);

const PencilSvg = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8364 0.86377L11.5909 3.10922L16.0818 7.60013L18.3273 5.35468L13.8364 0.86377ZM9.34547 5.35468L0.363647 14.3365V18.8274H4.85456L13.8364 9.84559L9.34547 5.35468Z"
      fill="white"
    />
  </svg>
);

const ArrowLeftSvg = ({ color }) => (
  <svg
    width="31"
    height="16"
    viewBox="0 0 31 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.625 0L0 7.66955L11.625 15.1872V10.1248H31V5.06241H11.625V0Z"
      fill={color || "white"}
    />
  </svg>
);

const WalletSvg = ({ color }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.37397 8.88309C3.37397 8.41409 3.7508 8.03375 4.2163 8.03375H8.71497C9.18164 8.03375 9.55847 8.41409 9.55847 8.88309C9.55893 8.99432 9.53748 9.10455 9.49534 9.20749C9.4532 9.31043 9.39119 9.40406 9.31287 9.48303C9.23454 9.56201 9.14142 9.62479 9.03884 9.66778C8.93625 9.71077 8.8262 9.73313 8.71497 9.73359H4.2163C3.99187 9.73235 3.7771 9.64209 3.61916 9.48262C3.46123 9.32316 3.37304 9.10752 3.37397 8.88309Z"
      fill="#0FA958"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.386 9.3381C22.3137 9.33343 22.2343 9.33344 22.1527 9.33344H18.9175C16.268 9.33344 14 11.3588 14 14.0001C14 16.6414 16.268 18.6668 18.9175 18.6668H22.1527C22.2343 18.6668 22.3137 18.6668 22.386 18.6621C23.4628 18.5968 24.4148 17.7789 24.4953 16.6356C24.5 16.5609 24.5 16.4804 24.5 16.4058V11.5944C24.5 11.5198 24.5 11.4393 24.4953 11.3646C24.4148 10.2213 23.4628 9.4046 22.386 9.3381ZM18.6328 15.2449C19.3142 15.2449 19.8672 14.6873 19.8672 14.0001C19.8672 13.3118 19.3142 12.7553 18.6328 12.7553C17.9503 12.7553 17.3962 13.3129 17.3962 14.0001C17.3962 14.6884 17.9503 15.2449 18.6328 15.2449Z"
      fill="#0FA958"
    />
    <path
      opacity="0.5"
      d="M22.33 9.33573C22.33 7.95789 22.2787 6.47973 21.399 5.42156C21.313 5.3179 21.2223 5.21824 21.1272 5.12289C20.2533 4.25023 19.1462 3.86289 17.7788 3.67856C16.4488 3.50006 14.7513 3.50006 12.607 3.50006H10.143C7.99867 3.50006 6.3 3.50006 4.97 3.67856C3.60267 3.86289 2.4955 4.25023 1.62167 5.12289C0.749 5.99673 0.361667 7.10389 0.177333 8.47123C-5.21541e-08 9.80123 0 11.4987 0 13.6431V13.7737C0 15.9181 1.39077e-07 17.6167 0.1785 18.9456C0.362833 20.3129 0.750167 21.4201 1.62283 22.2939C2.49667 23.1666 3.60383 23.5539 4.97117 23.7382C6.30117 23.9167 7.99867 23.9167 10.143 23.9167H12.607C14.7513 23.9167 16.45 23.9167 17.7788 23.7382C19.1462 23.5539 20.2533 23.1666 21.1272 22.2939C21.3596 22.0607 21.5654 21.8025 21.7408 21.5239C22.2658 20.6839 22.3288 19.6549 22.3288 18.6656L22.1538 18.6667H18.9175C16.268 18.6667 14 16.6414 14 14.0001C14 11.3587 16.268 9.33339 18.9175 9.33339H22.1527C22.2133 9.33339 22.274 9.33339 22.33 9.33573Z"
      fill="#0FA958"
    />
    <path
      d="M9.45115 0.667424L6.99998 2.32409L4.97815 3.67742C6.30582 3.50009 8.00215 3.50009 10.1418 3.50009H12.607C14.7513 3.50009 16.45 3.50009 17.7788 3.67859C18.0471 3.71359 18.3038 3.75792 18.5511 3.81159L16.3333 2.33342L13.8681 0.667424C13.2138 0.232195 12.4455 0 11.6596 0C10.8738 0 10.1055 0.232195 9.45115 0.667424Z"
      fill="#0FA958"
    />
  </svg>
);

/* ----- END OF SVG ----- */

/* icon */
const HomeMenuIcon = (props) => <Icon component={HomeSvg} {...props} />;

const AbsenceMenuIcon = (props) => <Icon component={AbsenceSvg} {...props} />;

const CashMenuIcon = (props) => <Icon component={CashSvg} {...props} />;

const AccountMenuIcon = (props) => <Icon component={AccountSvg} {...props} />;

const AdminMenuIcon = (props) => <Icon component={AdminSvg} {...props} />;

const SearchIcon = (props) => <Icon component={SearchSvg} {...props} />;
const MoreMenuIcon = (props) => <Icon component={MoreMenuSvg} {...props} />;
const CloseIcon = (props) => <Icon component={CloseSvg} {...props} />;
const TaskIcon = (props) => <Icon component={TaskSvg} {...props} />;
const BirthdayIcon = (props) => <Icon component={PartySvg} {...props} />;
const TrackingIcon = (props) => <Icon component={TrackingSvg} {...props} />;
const TrackingNonActiveIcon = (props) => (
  <Icon component={TrackingNonActiveSvg} {...props} />
);
const BackIcon = ({ color }) => <ArrowLeftSvg color={color} />;
const PencilIcon = (props) => <Icon component={PencilSvg} {...props} />;
const WalletIcon = (props) => <Icon component={WalletSvg} {...props} />;

export {
  AbsenceMenuIcon,
  AccountMenuIcon,
  AdminMenuIcon,
  BackIcon,
  BirthdayIcon,
  CashMenuIcon,
  CloseIcon,
  HomeMenuIcon,
  MoreMenuIcon,
  PencilIcon,
  SearchIcon,
  TaskIcon,
  TrackingIcon,
  TrackingNonActiveIcon,
  WalletIcon,
};
